import React from "react";
import { Link } from "react-scroll";

import Image from "../assets/img/about.jpg";

const About = () => {
  return (
    <section className="section bg-secondary" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row gap-24">
          <img
            src={Image}
            alt="about"
            className="object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded-2xl"
          />
          <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
            <div className="flex flex-col">
              <h2
                className="text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about
              relative before:absolute before::opacity-40 before:-top-[2rem] before:hidden before:lg:block"
              >
                Dhruv Rana
              </h2>
              <p className="mb-4 text-accent">Web developer </p>
              <hr className="mb-8 opacity-5" />
              <p className="mb-8">
                Dedicated and accomplished Software Engineer with over 4+ years
                of experience in frontend development. Proficient in
                technologies such as React.js, Angular, and Node.js, I have a
                strong track record of successfully migrating components,
                developing within design systems, and integrating innovative
                features. I'm passionate about collaborating within platform
                teams and supporting internal teams to deliver exceptional user
                experiences. My commitment to continuous learning and
                improvement ensures that I stay at the forefront of industry
                trends. With a keen eye for detail and a drive for excellence, I
                am poised to contribute effectively to any dynamic and ambitious
                project.
              </p>
            </div>

            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={2000}
              delay={0}
              isDynamic={true}
            >
              <button className="btn btn-md bg-accent hover:bg-accent-hover transition-all">
                Contact Me
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
