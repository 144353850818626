import React from "react";
import Projects from "./Projects";

const Portfolio = () => {
  return (
    <section className="bg-primary section min-h-[1400px]" id="portfolio">
      <div className="container mx-auto">
        <div className="flex flex-col items-center text-center">
          <h2 className="section-title before:content-portfolio relative before:absolute before:opacity:40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block">
            My Latest work
          </h2>
          <p className="subtitle">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quibusdam, quos, voluptates quod quia quae voluptate
            dolorum quas quidem fugit. Quisquam voluptatum, quibusdam, quos,
            voluptates quod quia quae voluptate dolorum quas quidem fugit.
          </p>
        </div>
        <Projects />
      </div>
    </section>
  );
};

export default Portfolio;
